<template>
  <div class="box">
    <div class="message-box" ref="msgBox">
      <div class="load" @click="getAll()" v-show="load">加载更多</div>
      <div
        class="message"
        v-for="(l, index) in list"
        :key="index"
        :style="myId == l.sender ? 'flex-direction:row-reverse' : ''"
      >
        <!-- <img src="@/img/consultant.png" class="head" /> -->
        <img
          :src="
            myId == l.sender
              ? my.userPhotoFullPath
              : consultant.profilePhotoFullPath
          "
          class="head"
        />
        <div class="nr" :style="myId == l.sender ? 'background:#FBECB3' : ''">
          {{ l.content }}
        </div>
      </div>
      <div ref="scrollEnd"></div>
    </div>
    <div class="bottom">
      <input type="text" class="val" v-model="val" />
      <div class="send" @click="send">发送</div>
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      chartData: {},
      list: [],
      myId: "",
      consultant: {},
      my: {},
      val: "",
      load: true,
    };
  },
  props: ["chatVal"],
  watch: {
    chatVal: function (val) {
      console.log(val); // 接收父组件的值
      this.list.push(val[0]);

      // this.list = this.list.concat(val.reverse());
      // console.log(this.list, 11111111111111);
      this.$http
        .put(url.sequence + `/${val[0].sequenceId}/${val[0].conversationId}`)
        .then((res) => {
          console.log(res);
        });
    },
  },
  created() {
    this.myId = localStorage.getItem("wql_user_id");
    this.chartData = JSON.parse(localStorage.getItem("msg"));
    this.getConsultant();
    this.getMy();
    this.getList(0);
  },
  methods: {
    //获取顾问信息
    getConsultant() {
      this.$http.get(url.consultant).then((res) => {
        this.consultant = res.data;
        if (!this.consultant.profilePhotoFullPath) {
          this.consultant.profilePhotoFullPath = require("@/img/my/user-photo.png");
        }
      });
    },
    //获取自己信息
    getMy() {
      this.$http.get(url.userInfo).then((res) => {
        this.my = res.data;
        if (!this.my.userPhotoFullPath) {
          this.my.userPhotoFullPath = require("@/img/my/user-photo.png");
        }
      });
    },
    //获取历史记录
    getList(sequenceId) {
      this.$http
        .get(
          url.chatHistory + `/${sequenceId}/${this.chartData.conversationId}`
        )
        .then((res) => {
          if (res.code == 200) {
            // this.list = res.data.message.reverse();
            // this.list = this.list.unshift(res.data.message.reverse());
            this.list = this.list.reverse().concat(res.data.message).reverse();
            if (res.data.message.length < 20) {
              this.load = false;
            }
            //页面滚动到最后一项
            this.$nextTick(() => {
              this.$refs.scrollEnd.scrollIntoView();
            });
          }
        });
    },
    //发送消息
    send() {
      this.$http
        .post(url.userSend, {
          toUserId: this.consultant.adminId,
          message: this.val,
        })
        .then((res) => {
          if (res.code == 200) {
            this.list.push({ content: this.val, sender: this.myId });
            this.val = "";
            this.$nextTick(() => {
              this.$refs.scrollEnd.scrollIntoView();
            });
          }
        });
    },
    //加载更多
    getAll() {
      this.getList(this.list[0].sequenceId);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: 100vh;
  .message-box {
    width: 100%;
    height: calc(100vh - 110px);
    // margin-top: 55px;
    overflow-y: scroll;
    padding-top: 55px;
    padding-bottom: 20px;
    // padding-bottom: 50px;
  }
  .load {
    color: lightblue;
    text-align: center;
    font-size: 16px;
  }
  .message {
    display: flex;
    margin-top: 15px;
    .head {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-left: 11px;
    }
    .nr {
      padding: 13px 11px;
      background: #ffffff;
      border-radius: 5px;
      margin-left: 8px;
      font-size: 14px;
      color: #000000;
      max-width: 280px;
    }
  }
  .bottom {
    width: 375px;
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    .val {
      width: 300px;
      height: 34px;
      background: #ffffff;
      border-radius: 17px;
      border: none;
      margin-left: 11px;
      margin-right: 11px;
      font-size: 16px;
      padding: 0 8px;
    }
    .send {
      color: #113fc4;
      font-size: 15px;
      font-weight: bold;
    }
  }
  .back {
    position: relative;
    font-size: 20px;
    right: 10px;
  }
}
</style>
